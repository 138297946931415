

























































import Vue from "vue";
import { Prop } from "vue/types/options";
import { createNamespacedHelpers } from "vuex";

import { Business, Campaign, Role } from "@/types";

import campaignStoreModule from "@/store/modules/campaign";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

const { mapActions: campaignActions } =
  createNamespacedHelpers("CAMPAIGN_FORM");

export default Vue.extend<any, any, any, any>({
  name: "CampaingForm",
  props: {
    campaign: {
      type: Object as Prop<Campaign>,
    },
  },
  data: () => ({
    valid: false,
    title: "",
    type: "",
    expiresOn: "",
    startedOn: "",
    status: "",
    types: ["voucher", "discount", "sms", "email"],
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    getRules: () => (field: string) =>
      [(v: string) => !!v || `${field} field is required`],
  },
  created() {
    this.resetForm();
  },
  methods: {
    ...campaignActions(["createCampaign", "updateCampaign"]),
    validateForm() {
      (
        this.$refs.campaignForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!this.valid) return;

      if (this.campaign && this.campaign._id) {
        this.updateCampaign({
          id: this.campaign._id,
          campaign: {
            title: this.title,
            type: this.type,
            expiresOn: this.expiresOn,
            startedOn: this.startedOn,
            status: this.status,
          },
        }).then((campaign) => {
          if (campaign) {
            this.$emit("data-saved", campaign);
          }
        });
      } else {
        const businessId = (this.role.business as Business)._id;
        this.createCampaign({
          title: this.title,
          type: this.type,
          expiresOn: this.expiresOn,
          startedOn: this.startedOn,
          businessId,
        }).then((campaign) => {
          if (campaign) {
            this.$emit("data-saved", campaign);
          }
        });
      }
    },
    resetForm() {
      let expiresOn = "";
      let startedOn = "";
      if (this.campaign) {
        expiresOn = moment(this.campaign.expiresOn).tz(tz).format("YYYY-MM-DD");
        startedOn = moment(this.campaign.startedOn).tz(tz).format("YYYY-MM-DD");
      }
      this.title = this.campaign?.title || "";
      this.type = this.campaign?.type || "";
      this.expiresOn = expiresOn || "";
      this.startedOn = startedOn || "";
      this.status = this.campaign?.status || "";

      (
        this.$refs.campaignForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CAMPAIGN_FORM")) {
      this.$store.registerModule("CAMPAIGN_FORM", campaignStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CAMPAIGN_FORM");
  },
});
