import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Advertisement, Page } from "@/types";

type AdvertisementState = {
  advertisementPage: Page<Advertisement>;
};

const advertisement: Module<AdvertisementState, unknown> = {
  namespaced: true,
  state: () => ({
    advertisementPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getAdvertisement: (state) => (advertisementId: string) =>
      state.advertisementPage.docs.find((a) => a._id === advertisementId),
    advertisementPage: (state) => state.advertisementPage,
  },
  mutations: {
    ADD_ADVERTISEMENT: (state, _advertisement) => {
      let idx = -1;
      state.advertisementPage.docs.map((a, i) => {
        if (a._id === _advertisement._id) idx = i;
      });
      if (idx === -1) state.advertisementPage.docs.push(_advertisement);
      else Vue.set(state.advertisementPage.docs, idx, _advertisement);
    },
    SET_ADVERTISEMENT_PAGE: (state, list) => {
      state.advertisementPage = list;
    },
  },
  actions: {
    fetchAdvertisement(context, params = "") {
      api
        .get(`/v1/advertisement${params}`)
        .then((response) => {
          context.commit("ADD_ADVERTISEMENT", response.data.advertisement);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchAdvertisementList(context, params = "") {
      return await api
        .get(`/v1/advertisement${params}`)
        .then((response) => {
          context.commit(
            "SET_ADVERTISEMENT_PAGE",
            response.data.advertisementPage
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createAdvertisement(context, payload) {
      return await api
        .post(`/v1/advertisement`, payload, { timeout: 20000 }) // Uploading some images could take time!
        .then((response) => {
          context.commit("ADD_ADVERTISEMENT", response.data.advertisement);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Staff created",
            },
            { root: true }
          );
          return response.data.advertisement;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateAdvertisement(
      context,
      data: { id: string; advertisement: Advertisement }
    ) {
      return await api
        .put(`/v1/advertisement/${data.id}`, data.advertisement)
        .then((response) => {
          context.commit("ADD_ADVERTISEMENT", response.data.advertisement);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Ad updated",
            },
            { root: true }
          );
          return response.data.advertisement;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async exportAdvertisements(context, params = "") {
      return await api
        .get(`/v1/advertisement/export${params}`)
        .then((response) => response)
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default advertisement;
