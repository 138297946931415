import { ClientCategory, Page } from "@/types";
import { api } from "@/util/axios";
import Vue from "vue";
import { Module } from "vuex";

type ClientCategoryState = {
  categoryPage: Page<ClientCategory>;
  categoryCount: number;
};

const clientCategory: Module<ClientCategoryState, unknown> = {
  namespaced: true,
  state: () => ({
    categoryPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    categoryCount: 0,
  }),
  getters: {
    clientCategoryPage: (state) => state.categoryPage,
  },
  mutations: {
    ADD_CATEGORY: (state, _category) => {
      let idx = -1;
      state.categoryPage.docs.map((a, i) => {
        if (a._id === _category._id) idx = i;
      });
      if (idx === -1) state.categoryPage.docs.push(_category);
      else Vue.set(state.categoryPage.docs, idx, _category);
    },
    SET_CATEGORY_PAGE: (state, list) => {
      state.categoryPage = list;
    },
  },
  actions: {
    async createCategory(context, payload) {
      return await api
        .post("/v1/client/category", payload)
        .then((response) => {
          context.commit("ADD_CATEGORY", response.data.category);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Client Category created",
            },
            { root: true }
          );
          return response.data.category;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async addClientToCategory(context, payload) {
      return await api
        .post("/v1/client/category/add", payload)
        .then((response) => {
          context.commit("ADD_CATEGORY", response.data.category);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Client is Added",
            },
            { root: true }
          );
          return response.data.category;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async removeClientFromCategory(context, payload) {
      return await api
        .post("/v1/client/category/remove", payload)
        .then((response) => {
          context.commit("ADD_CATEGORY", response.data.category);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Client is Removed",
            },
            { root: true }
          );
          return response.data.category;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchClientCategoryList(context, params = "") {
      api
        .get(`/v1/client/category${params}`)
        .then((response) => {
          context.commit("SET_CATEGORY_PAGE", response.data.clientCategoryPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default clientCategory;
