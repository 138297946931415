









































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

import smsStoreModule from "@/store/modules/sms";
import { Business, Role } from "@/types";
import SMSForm from "./SMSForm.vue";

const { mapActions: smsActions, mapGetters: smsGetters } =
  createNamespacedHelpers("SMS_FORM");
const API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  name: "SMSList",
  components: {
    "sms-form": SMSForm,
  },
  data: () => ({
    apiUrl: API_URL,
    q: "",
    smsFormDialog: false,
  }),
  computed: {
    ...smsGetters(["smsPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  created() {
    this.fetchSMSs();
  },
  filters: {
    initials(str: string) {
      if (!str) return;
      let arr = str.split(" ");
      let initials = "";
      arr.map((n) => (initials += n.charAt(0).toUpperCase()));

      return initials;
    },
    date(str: string) {
      if (!str) return;

      return moment(str).tz(tz).format("d/M/Y h:mm a");
    },
  },
  methods: {
    ...smsActions(["fetchSMSList"]),
    fetchSMSs(q?: string) {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        let params = `?businessId=${bid}`;

        if (q) params += `&q=${q}`;

        this.fetchSMSList(params);
      }
    },
    searchSMS() {
      if (!this.q) return;
      this.fetchSMSs(this.q);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SMS_FORM")) {
      this.$store.registerModule("SMS_FORM", smsStoreModule);
    }
  },
  beforeDestroy() {
    // this.$store.unregisterModule("SMS_FORM");
  },
});
