























































































































































































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

import CampaignForm from "./CampaignForm.vue";
import campaignStoreModule from "@/store/modules/campaign";
import messageStoreModule from "@/store/modules/message";
import clientCategoryStoreModule from "@/store/modules/clientCategories";
import { Business, Role, VendorEmailTemplate } from "@/types";
import emailTemplate from "@/store/modules/vendorEmailTemplate";
import {
  australiaCountryHolidays,
  kenyaCountryHolidays,
} from "@/util/constants";

const { mapActions: campaignActions, mapGetters: campaignGetters } =
  createNamespacedHelpers("CAMPAIGN_FORM");

const { mapActions: messageActions } = createNamespacedHelpers("MESSAGE_FORM");
const { mapActions: emailTemplateActions, mapGetters: emailTemplateGetters } =
  createNamespacedHelpers("TEMPLATE_LIST");

const { mapActions: clientCategoryActions, mapGetters: clientCategoryGetters } =
  createNamespacedHelpers("CLIENT_CATEGORY");

export default Vue.extend<any, any, any, any>({
  components: { CampaignForm },
  name: "MessageForm",
  data: () => ({
    campaignDialog: false,
    valid: false,
    subject: "",
    campaignId: "",
    to: "all_clients",
    date_: new Date(),
    template: undefined as undefined | VendorEmailTemplate,
    selectedCategoryIds: [],
    frequency: "" as
      | "daily"
      | "weekly"
      | "monthly"
      | "annually"
      | "other-dates",
    frequencies: [
      { text: "Daily", value: "daily" },
      { text: "Weekly", value: "weekly" },
      { text: "Monthly", value: "monthly" },
      { text: "Annually", value: "annually" },
      { text: "Specific Dates", value: "other-dates" },
    ],
    scheduleDate: "",
    dateFormatted: "",
    dateMenu: false,
    schedule: "",
    dateMenu2: false,
    dateFormatted2: "",
    dateMenu3: false,
    dateFormatted3: "",
    endDate: "",
    startDate: "",
    submitted: false,
    datesMenu: false,
    dates: [] as Date[],
    clientsBirthday: false,
    countryHolidays: false,
    businessBirthday: false,
    otherAnniversaries: false,
    selectedCountryHolidays: [],
  }),
  computed: {
    ...campaignGetters(["campaignPage"]),
    ...emailTemplateGetters(["vendorEmailTemplatePage"]),
    ...clientCategoryGetters(["clientCategoryPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
    getRules: () => (field: string) =>
      [(v: string) => !!v || `${field} field is required`],
    countryHolidayList() {
      const countryCode = localStorage.getItem("country-alpha3-code");

      if (countryCode === "KEN") return kenyaCountryHolidays;
      else if (countryCode === "AUD") return australiaCountryHolidays;

      return [];
    },
  },
  filters: {
    date(str: string) {
      if (!str) return;

      return moment(str).tz(tz).format("MMM D,YYYY - h:mma");
    },
  },
  created() {
    this.fetchCampaigns();
    this.fetchData();
  },
  methods: {
    ...campaignActions(["fetchCampaignList"]),
    ...messageActions(["createMessage"]),
    ...emailTemplateActions(["fetchVendorEmailTemplateList"]),
    ...clientCategoryActions(["fetchClientCategoryList"]),
    validateForm() {
      (
        this.$refs.form as Element & {
          validate: () => boolean;
        }
      )?.validate();

      this.submitted = true;

      if (!this.valid || !this.template) return;

      const schedule: {
        dates?: string[];
        frequency?: "daily" | "weekly" | "monthly" | "annually" | "other-dates";
        endDate?: string;
        startDate?: string;
        clientsBirthday?: boolean;
        countryHolidays?: boolean;
        businessBirthday?: boolean;
        otherAnniversaries?: boolean;
      } = {};

      schedule.startDate = new Date(this.startDate).toISOString();
      schedule.endDate = new Date(this.endDate).toISOString();

      if (this.schedule === "date") {
        if (this.dateFormatted) {
          let date: string | string[] = this.dateFormatted;

          date = (date as string).split("/");

          date = `${date[1]}-${date[0]}-${date[2]}`;
          schedule.dates = [new Date(date).toISOString()];
        }

        schedule.clientsBirthday = this.clientsBirthday;
        schedule.countryHolidays = this.countryHolidays;
        schedule.businessBirthday = this.businessBirthday;
        schedule.otherAnniversaries = this.otherAnniversaries;
      }
      // if (this.endDate) {
      //   schedule.frequency = this.frequency;
      //   let date: string | string[] = this.dateFormatted2;

      //   date = date.split("/");

      //   date = `${date[1]}-${date[0]}-${date[2]}`;
      //   schedule.endDate = new Date(date).toISOString();
      // }
      if (this.frequency) schedule.frequency = this.frequency;

      if (schedule.frequency == "other-dates") {
        schedule.dates = this.dates.map((d) => new Date(d).toISOString());
      }

      const payload = {
        subject: this.subject,
        campaignId: this.campaignId,
        to: this.to,
        businessId: (this.role.business as Business)._id,
        schedule,
        clientCategories: this.selectedCategoryIds,
        vendorEmailTemplateId: this.template?._id,
      };

      this.createMessage(payload).then(() => {
        this.submitted = false;
        this.$emit("data-saved");
      });
    },
    fetchCampaigns() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;

        this.fetchCampaignList(`?businessId=${bid}`);
      }
    },
    searchCampaign(q: string) {
      if (!q) return;
      const bid = (this.role.business as Business)._id;
      this.fetchCampaignList(`?businessId=${bid}&q=${q}`);
    },
    fetchData() {
      if (this.role) {
        const params = `?businessId=${(this.role.business as Business)?._id}`;
        this.fetchClientCategoryList(params);
        this.fetchVendorEmailTemplateList(params + "&status=active");
      }
    },
    formatDate(date: string) {
      if (!date) return null;

      return moment(date).tz(tz).format("DD/MM/YYYY");
    },
    parseDate(date: string) {
      if (!date) return null;
      let d: string | string[] = date.split("/");

      d = `${d[2]}-${d[1]}-${d[0]}`;

      return moment(d).tz(tz).format("YYYY-MM-DD");
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CAMPAIGN_FORM")) {
      this.$store.registerModule("CAMPAIGN_FORM", campaignStoreModule);
    }
    if (!this.$store.hasModule("MESSAGE_FORM")) {
      this.$store.registerModule("MESSAGE_FORM", messageStoreModule);
    }
    if (!this.$store.hasModule("TEMPLATE_LIST")) {
      this.$store.registerModule("TEMPLATE_LIST", emailTemplate);
      if (!this.$store.hasModule("CLIENT_CATEGORY")) {
        this.$store.registerModule(
          "CLIENT_CATEGORY",
          clientCategoryStoreModule
        );
      }
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CAMPAIGN_FORM");
    this.$store.unregisterModule("MESSAGE_FORM");
    this.$store.unregisterModule("TEMPLATE_LIST");
    this.$store.unregisterModule("CLIENT_CATEGORY");
  },
});
