import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Campaign, Page } from "@/types";

type CampaignState = {
  campaignPage: Page<Campaign>;
};

const campaign: Module<CampaignState, unknown> = {
  namespaced: true,
  state: () => ({
    campaignPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getCampaign: (state) => (campaignId: string) =>
      state.campaignPage.docs.find((c) => c._id === campaignId),
    campaignPage: (state) => state.campaignPage,
  },
  mutations: {
    ADD_CAMPAIGN: (state, _campaign) => {
      let idx = -1;
      state.campaignPage.docs.map((a, i) => {
        if (a._id === _campaign._id) idx = i;
      });
      if (idx === -1) state.campaignPage.docs.push(_campaign);
      else Vue.set(state.campaignPage.docs, idx, _campaign);
    },
    SET_CAMPAIGN_PAGE: (state, list) => {
      state.campaignPage = list;
    },
  },
  actions: {
    fetchCampaign(context, params = "") {
      api
        .get(`/v1/campaign${params}`)
        .then((response) => {
          context.commit("ADD_CAMPAIGN", response.data.campaign);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchCampaignList(context, params = "") {
      return await api
        .get(`/v1/campaign${params}`)
        .then((response) => {
          context.commit("SET_CAMPAIGN_PAGE", response.data.campaignPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createCampaign(context, payload) {
      return await api
        .post(`/v1/campaign`, payload, { timeout: 20000 }) // Uploading some images could take time!
        .then((response) => {
          context.commit("ADD_CAMPAIGN", response.data.campaign);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Staff created",
            },
            { root: true }
          );
          return response.data.campaign;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateCampaign(context, data: { id: string; campaign: Campaign }) {
      return await api
        .put(`/v1/campaign/${data.id}`, data.campaign)
        .then((response) => {
          context.commit("ADD_CAMPAIGN", response.data.campaign);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Campaign updated",
            },
            { root: true }
          );
          return response.data.campaign;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async exportCampaigns(context, params = "") {
      return await api
        .get(`/v1/campaign/export${params}`)
        .then((response) => response)
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default campaign;
