var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"px-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Filter ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticStyle:{"float":"right"},attrs:{"to":"/marketing/ad/create","small":""}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-plus")]),_vm._v(" Create ")],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.advertisementPage.docs,"options":_vm.options,"server-items-length":_vm.advertisementPage.total,"no-data-text":"No advertisement to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.startsOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startsOn))+" ")]}},{key:"item.expiresOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.expiresOn))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.editAdvertisement(item)}}},[_vm._v(" Details ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }