






















































import Vue from "vue";
import { Business, Campaign, Role } from "@/types";
import { createNamespacedHelpers } from "vuex";

import campaignStoreModule from "@/store/modules/campaign";
import CampaignForm from "./CampaignForm.vue";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

const { mapActions: campaignActions, mapGetters: campaignGetters } =
  createNamespacedHelpers("CAMPAIGN_LIST");

export default Vue.extend<any, any, any, any>({
  components: { CampaignForm },
  name: "CampaignList",
  props: {
    campaignStatus: {
      type: String,
      default: "active",
    },
  },
  data: () => ({
    headers: [
      {
        text: "Type",
        align: "start",
        sortable: true,
        value: "type",
      },
      { text: "Title", value: "title" },
      { text: "Started On", value: "startedOn" },
      { text: "Expires On", value: "expiresOn" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
    campaign: undefined as undefined | Campaign,
    showDialog: false,
  }),
  filters: {
    formatDate(date: string) {
      if (!date) return null;

      return moment(date).tz(tz).format("DD/MM/YYYY");
    },
  },
  watch: {
    role() {
      if (this.role) {
        this.fetchCampaigns();
      }
    },
    options: {
      handler() {
        this.fetchCampaigns();
      },
      deep: true,
    },
  },
  computed: {
    ...campaignGetters(["campaignPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...campaignActions(["fetchCampaignList"]),
    editCampaign(campaign: Campaign) {
      this.campaign = campaign;
      this.showDialog = true;
    },
    fetchCampaigns() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        const page = this.options.page || 1;
        this.fetchCampaignList(
          `?businessId=${bid}&page=${page}&status=${this.campaignStatus}`
        );
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CAMPAIGN_LIST")) {
      this.$store.registerModule("CAMPAIGN_LIST", campaignStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CAMPAIGN_LIST");
  },
});
