<template>
  <div style="width: 100%; overflow: hidden">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <v-card class="flex-sm-wrap align-center px-3 py-3" outlined>
          <v-row no-gutters>
            <v-col cols="12" md="6" class="justify-center align-center p-4">
              <v-img
                src="../assets/mkt2.png"
                alt="Illustration"
                class="w-100"
              />
            </v-col>
            <v-col cols="12" md="6" class="p-5 flex">
              <div class="mt-2">
                <h1 class="responsive-text font-weight-bold small-width">
                  {{ title }}
                </h1>

                <p class="small-width">
                  {{ content }}
                </p>
                <p class="font-weight-bold">Subscribe for Ksh. 2,500 only.</p>
                <v-row class="mb-4 ml-1">
                  <v-btn
                    @click="
                      () => {
                        onCallBack();
                      }
                    "
                    color="orange"
                    class="mt-4"
                    >Start Now</v-btn
                  >
                  <v-btn
                    variant="outlined"
                    @click="
                      () => {
                        this.$router.go(-1);
                        onCancel();
                      }
                    "
                    class="mt-4 mx-4"
                    >Cancel</v-btn
                  >
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "AdvertMarketing",
  ata: () => ({
    advert: false,
  }),

  props: {
    title: {
      type: String,
      default: "Boost your business through targeted marketing campaigns",
    },
    content: {
      type: String,
      default: ` Unlock full access to our platform by subscribing today.
                      Gain unlimited usage and explore all features without
                      restrictions.`,
    },
    image: {
      type: String,
      default: "../assets/mkt.png",
    },
    onCallBack: {
      type: Function,
    },
    onCancel: {
      type: Function,
    },
  },
};
</script>
<style lang="css">
.responsive-text {
  font-size: 16px; /* Default font size */
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .responsive-text {
    font-size: 18px; /* Smaller font size for mobile */
  }
}

/* Media query for desktop devices */
@media (min-width: 601px) {
  .responsive-text {
    font-size: 34px; /* Larger font size for desktop */
  }
}
</style>
