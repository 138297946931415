



















import DefaultEmailTemplateList from "@/views/DefaultEmailTemplateList.vue";
import EmailTemplateList from "@/views/EmailTemplateList.vue";
import Vue from "vue";
import MessageList from "./MessageList.vue";

export default Vue.extend<any, any, any, any>({
  components: { MessageList, EmailTemplateList, DefaultEmailTemplateList },
  name: "Email",
  data: () => ({
    isMobile: false,
  }),
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize);
  },
});
