




































import Vue from "vue";
import { Business, Advertisement, Role } from "@/types";
import { createNamespacedHelpers } from "vuex";

import advertisementStoreModule from "@/store/modules/advertisement";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

const { mapActions: advertisementActions, mapGetters: advertisementGetters } =
  createNamespacedHelpers("ADVERTISEMENT_LIST");

export default Vue.extend<any, any, any, any>({
  name: "AdvertisementList",
  props: {
    advertisementStatus: {
      type: String,
      default: "active",
    },
  },
  data: () => ({
    headers: [
      { text: "Title", value: "title" },
      { text: "Start Date", value: "startsOn" },
      { text: "Expires On", value: "expiresOn" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
    advertisement: undefined as undefined | Advertisement,
    showDialog: false,
  }),
  filters: {
    formatDate(date: string) {
      if (!date) return null;

      return moment(date).tz(tz).format("DD/MM/YYYY");
    },
  },
  watch: {
    role() {
      if (this.role) {
        this.fetchAdvertisements();
      }
    },
    options: {
      handler() {
        this.fetchAdvertisements();
      },
      deep: true,
    },
  },
  computed: {
    ...advertisementGetters(["advertisementPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...advertisementActions(["fetchAdvertisementList"]),
    editAdvertisement(advertisement: Advertisement) {
      this.advertisement = advertisement;
      this.showDialog = true;
    },
    fetchAdvertisements() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        const page = this.options.page || 1;
        this.fetchAdvertisementList(
          `?businessId=${bid}&page=${page}&status=${this.advertisementStatus}`
        );
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("ADVERTISEMENT_LIST")) {
      this.$store.registerModule(
        "ADVERTISEMENT_LIST",
        advertisementStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("ADVERTISEMENT_LIST");
  },
});
