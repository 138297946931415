import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { EmailTemplate, Page } from "@/types";

type EmailTemplateState = {
  emailTemplatePage: Page<EmailTemplate>;
};

const emailTemplate: Module<EmailTemplateState, unknown> = {
  namespaced: true,
  state: () => ({
    emailTemplatePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getEmailTemplate: (state) => (emailTemplateId: string) =>
      state.emailTemplatePage.docs.find((c) => c._id === emailTemplateId),
    emailTemplatePage: (state) => state.emailTemplatePage,
  },
  mutations: {
    ADD_EMAIL_TEMPLATE: (state, _emailTemplate) => {
      let idx = -1;
      state.emailTemplatePage.docs.map((r, i) => {
        if (r._id === _emailTemplate._id) idx = i;
      });
      if (idx === -1) state.emailTemplatePage.docs.push(_emailTemplate);
      else Vue.set(state.emailTemplatePage.docs, idx, _emailTemplate);
    },
    SET_EMAIL_TEMPLATE_PAGE: (state, list) => {
      state.emailTemplatePage = list;
    },
    REMOVE_EMAIL_TEMPLATE(state, emailTemplate) {
      let idx = -1;
      state.emailTemplatePage.docs.map((r, i) => {
        if (r._id === emailTemplate._id) idx = i;
      });
      if (idx > -1) state.emailTemplatePage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchEmailTemplate(context, params = "") {
      api
        .get(`/v1/email-template${params}`)
        .then((response) => {
          context.commit("ADD_EMAIL_TEMPLATE", response.data.emailTemplate);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchEmailTemplateList(context, params = "") {
      api
        .get(`/v1/email-template${params}`)
        .then((response) => {
          context.commit(
            "SET_EMAIL_TEMPLATE_PAGE",
            response.data.emailTemplatePage
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default emailTemplate;
