





























































import Vue from "vue";
import { Business, Discount, Role } from "@/types";
import { createNamespacedHelpers } from "vuex";

import discountStoreModule from "@/store/modules/discount";
import DiscountForm from "./DiscountForm.vue";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

const { mapActions: discountActions, mapGetters: discountGetters } =
  createNamespacedHelpers("DISCOUNT_LIST");

export default Vue.extend<any, any, any, any>({
  components: { DiscountForm },
  name: "DiscountList",
  props: {
    discountStatus: {
      type: String,
      default: "active",
    },
  },
  data: () => ({
    headers: [
      { text: "Title", value: "title" },
      { text: "Value", value: "value" },
      { text: "Started On", value: "startedOn" },
      { text: "Expires On", value: "expiresOn" },
      { text: "Status", value: "status" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
    discount: undefined as undefined | Discount,
    showDialog: false,
  }),
  filters: {
    formatDate(date: string) {
      if (!date) return null;

      return moment(date).tz(tz).format("DD/MM/YYYY");
    },
  },
  watch: {
    role() {
      if (this.role) {
        this.fetchDiscounts();
      }
    },
    options: {
      handler() {
        this.fetchDiscounts();
      },
      deep: true,
    },
  },
  computed: {
    ...discountGetters(["discountPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...discountActions(["fetchDiscountList"]),
    editDiscount(discount: Discount) {
      this.discount = discount;
      this.showDialog = true;
    },
    fetchDiscounts() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        const page = this.options.page || 1;
        this.fetchDiscountList(
          `?businessId=${bid}&page=${page}&status=${this.discountStatus}&validate=false`
        );
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("DISCOUNT_LIST")) {
      this.$store.registerModule("DISCOUNT_LIST", discountStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("DISCOUNT_LIST");
  },
});
