import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Message, Page } from "@/types";

type MessageState = {
  messagePage: Page<Message>;
};

const message: Module<MessageState, unknown> = {
  namespaced: true,
  state: () => ({
    messagePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getMessage: (state) => (messageId: string) =>
      state.messagePage.docs.find((c) => c._id === messageId),
    messagePage: (state) => state.messagePage,
  },
  mutations: {
    ADD_MESSAGE: (state, _message) => {
      let idx = -1;
      state.messagePage.docs.map((r, i) => {
        if (r._id === _message._id) idx = i;
      });
      if (idx === -1) state.messagePage.docs.push(_message);
      else Vue.set(state.messagePage.docs, idx, _message);
    },
    SET_MESSAGE_PAGE: (state, list) => {
      state.messagePage = list;
    },
    REMOVE_MESSAGE(state, message) {
      let idx = -1;
      state.messagePage.docs.map((r, i) => {
        if (r._id === message._id) idx = i;
      });
      if (idx > -1) state.messagePage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchMessage(context, params = "") {
      api
        .get(`/v1/message${params}`)
        .then((response) => {
          context.commit("ADD_MESSAGE", response.data.message);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchMessageList(context, params = "") {
      api
        .get(`/v1/message${params}`)
        .then((response) => {
          context.commit("SET_MESSAGE_PAGE", response.data.messagePage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createMessage(context, payload) {
      return await api
        .post(`/v1/message`, payload)
        .then((response) => {
          context.commit("ADD_MESSAGE", response.data.message);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Message sent",
            },
            { root: true }
          );
          return response.data.message;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async bulkCreateMessage(context, payload) {
      return await api
        .post<{ messages: Message[] }>(`/v1/message/bulk`, payload)
        .then((response) => {
          response.data.messages.map((m) => context.commit("ADD_MESSAGE", m));
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Message sent",
            },
            { root: true }
          );
          return response.data.messages;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updateMessage(context, data: { id: string; message: Message }) {
      api
        .put(`/v1/message/${data.id}`, data.message)
        .then((response) => {
          context.commit("ADD_MESSAGE", response.data.message);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Message updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteMessage(context, id) {
      api
        .delete(`/v1/message${id}`)
        .then((response) => {
          context.commit("REMOVE_MESSAGE", response.data.message);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Message deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default message;
