import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { SMS, Page } from "@/types";

type SMSState = {
  smsPage: Page<SMS>;
};

const sms: Module<SMSState, unknown> = {
  namespaced: true,
  state: () => ({
    smsPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getSMS: (state) => (smsId: string) =>
      state.smsPage.docs.find((c) => c._id === smsId),
    smsPage: (state) => state.smsPage,
  },
  mutations: {
    ADD_SMS: (state, _sms) => {
      let idx = -1;
      state.smsPage.docs.map((r, i) => {
        if (r._id === _sms._id) idx = i;
      });
      if (idx === -1) state.smsPage.docs.push(_sms);
      else Vue.set(state.smsPage.docs, idx, _sms);
    },
    SET_SMS_PAGE: (state, list) => {
      state.smsPage = list;
    },
    REMOVE_SMS(state, sms) {
      let idx = -1;
      state.smsPage.docs.map((r, i) => {
        if (r._id === sms._id) idx = i;
      });
      if (idx > -1) state.smsPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchSMS(context, params = "") {
      api
        .get(`/v1/marketing/sms${params}`)
        .then((response) => {
          context.commit("ADD_SMS", response.data.sms);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.sms,
            },
            { root: true }
          );
        });
    },
    fetchSMSList(context, params = "") {
      api
        .get(`/v1/marketing/sms${params}`)
        .then((response) => {
          context.commit("SET_SMS_PAGE", response.data.smsPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.sms,
            },
            { root: true }
          );
        });
    },
    async createSMS(context, payload) {
      return await api
        .post(`/v1/marketing/sms`, payload)
        .then((response) => {
          context.commit("ADD_SMS", response.data.sms);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "SMS sent",
            },
            { root: true }
          );
          return response.data.sms;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.sms,
            },
            { root: true }
          );
        });
    },
    async bulkCreateSMS(context, payload) {
      return await api
        .post<{ smss: SMS[] }>(`/v1/marketing/marketing/sms/bulk`, payload)
        .then((response) => {
          response.data.smss.map((m) => context.commit("ADD_SMS", m));
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "SMS sent",
            },
            { root: true }
          );
          return response.data.smss;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.sms,
            },
            { root: true }
          );
        });
    },
    updateSMS(context, data: { id: string; sms: SMS }) {
      api
        .put(`/v1/marketing/marketing/sms/${data.id}`, data.sms)
        .then((response) => {
          context.commit("ADD_SMS", response.data.sms);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "SMS updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.sms,
            },
            { root: true }
          );
        });
    },
    deleteSMS(context, id) {
      api
        .delete(`/v1/marketing/sms${id}`)
        .then((response) => {
          context.commit("REMOVE_SMS", response.data.sms);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "SMS deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.sms,
            },
            { root: true }
          );
        });
    },
  },
};

export default sms;
