import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { VendorPlan, Page } from "@/types";

type VendorPlanState = {
  vendorPlanPage: Page<VendorPlan>;
};

const vendorPlan: Module<VendorPlanState, unknown> = {
  namespaced: true,
  state: () => ({
    vendorPlanPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getVendorPlan: (state) => (vendorPlanId: string) =>
      state.vendorPlanPage.docs.find((c) => c._id === vendorPlanId),
    vendorPlanPage: (state) => state.vendorPlanPage,
  },
  mutations: {
    ADD_VENDOR_PLAN: (state, _vendorPlan) => {
      let idx = -1;
      state.vendorPlanPage.docs.map((r, i) => {
        if (r._id === _vendorPlan._id) idx = i;
      });
      if (idx === -1) state.vendorPlanPage.docs.push(_vendorPlan);
      else Vue.set(state.vendorPlanPage.docs, idx, _vendorPlan);
    },
    SET_VENDOR_PLAN_PAGE: (state, list) => {
      state.vendorPlanPage = list;
    },
    REMOVE_VENDOR_PLAN(state, vendorPlan) {
      let idx = -1;
      state.vendorPlanPage.docs.map((r, i) => {
        if (r._id === vendorPlan._id) idx = i;
      });
      if (idx > -1) state.vendorPlanPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchVendorPlan(context, params = "") {
      api
        .get(`/v1/vendor-plan${params}`)
        .then((response) => {
          context.commit("ADD_VENDOR_PLAN", response.data.vendorPlan);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchVendorPlanList(context, params = "") {
      api
        .get(`/v1/vendor-plan${params}`)
        .then((response) => {
          context.commit("SET_VENDOR_PLAN_PAGE", response.data.vendorPlanPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createVendorPlan(context, payload) {
      return await api
        .post(`/v1/vendor-plan`, payload)
        .then((response) => {
          context.commit("ADD_VENDOR_PLAN", response.data.vendorPlan);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Plan subscribed",
            },
            { root: true }
          );
          return response.data.vendorPlan;
        })
        .catch((error) => {
          let message = error.response?.data?.error?.message as string;
          if (message.includes("E11000 duplicate key error collection"))
            message = "Plan already subscribed!";
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: message,
            },
            { root: true }
          );
        });
    },
    async updateVendorPlan(
      context,
      data: { id: string; vendorPlan: VendorPlan }
    ) {
      return await api
        .put(`/v1/vendor-plan/${data.id}`, data.vendorPlan)
        .then((response) => {
          context.dispatch("getLoggedInUser", null, { root: true });
          context.commit("ADD_VENDOR_PLAN", response.data.vendorPlan);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Plan updated",
            },
            { root: true }
          );
          return response.data.vendorPlan;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteVendorPlan(context, id) {
      api
        .delete(`/v1/vendor-plan${id}`)
        .then((response) => {
          context.commit("REMOVE_VENDOR_PLAN", response.data.vendorPlan);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Plan deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default vendorPlan;
