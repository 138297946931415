

























import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import emailTemplateStoreModule from "@/store/modules/emailTemplate";

const { mapGetters: emailTemplateGetters, mapActions: emailTemplateActions } =
  createNamespacedHelpers("DEFAULT_EMAIL_TEMPLATE_LIST");

export default Vue.extend<any, any, any, any>({
  name: "DefaultEmailTemplateList",
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Action", value: "action" },
    ],
    deleteEmailTemplateDialog: false,
    options: {} as { page: number },
  }),
  computed: {
    ...emailTemplateGetters(["emailTemplatePage"]),
  },
  watch: {
    role: "fetchEmailTemplates",
    options: {
      handler() {
        this.fetchEmailTemplateList();
      },
      deep: true,
    },
  },
  methods: {
    ...emailTemplateActions(["fetchEmailTemplateList"]),
    toggleDeleteEmailTemplate() {
      this.deleteEmailTemplateDialog = true;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("DEFAULT_EMAIL_TEMPLATE_LIST")) {
      this.$store.registerModule(
        "DEFAULT_EMAIL_TEMPLATE_LIST",
        emailTemplateStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("DEFAULT_EMAIL_TEMPLATE_LIST");
  },
});
