import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Discount, Page } from "@/types";

type DiscountState = {
  discountPage: Page<Discount>;
};

const discount: Module<DiscountState, unknown> = {
  namespaced: true,
  state: () => ({
    discountPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getDiscount: (state) => (discountId: string) =>
      state.discountPage.docs.find((c) => c._id === discountId),
    discountPage: (state) => state.discountPage,
  },
  mutations: {
    ADD_DISCOUNT: (state, _discount) => {
      let idx = -1;
      state.discountPage.docs.map((a, i) => {
        if (a._id === _discount._id) idx = i;
      });
      if (idx === -1) state.discountPage.docs.push(_discount);
      else Vue.set(state.discountPage.docs, idx, _discount);
    },
    SET_DISCOUNT_PAGE: (state, list) => {
      state.discountPage = list;
    },
  },
  actions: {
    fetchDiscount(context, params = "") {
      api
        .get(`/v1/discount${params}`)
        .then((response) => {
          context.commit("ADD_DISCOUNT", response.data.discount);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchDiscountList(context, params = "") {
      return await api
        .get(`/v1/discount${params}`)
        .then((response) => {
          context.commit("SET_DISCOUNT_PAGE", response.data.discountPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createDiscount(context, payload) {
      return await api
        .post(`/v1/discount`, payload, { timeout: 20000 }) // Uploading some images could take time!
        .then((response) => {
          context.commit("ADD_DISCOUNT", response.data.discount);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Staff created",
            },
            { root: true }
          );
          return response.data.discount;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateDiscount(context, data: { id: string; discount: Discount }) {
      return await api
        .put(`/v1/discount/${data.id}`, data.discount)
        .then((response) => {
          context.commit("ADD_DISCOUNT", response.data.discount);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Discount updated",
            },
            { root: true }
          );
          return response.data.discount;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async exportDiscounts(context, params = "") {
      return await api
        .get(`/v1/discount/export${params}`)
        .then((response) => response)
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default discount;
