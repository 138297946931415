var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"px-5"},[_c('v-dialog',{attrs:{"width":"400px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[(_vm.showDialog)?_c('campaign-form',{attrs:{"campaign":_vm.campaign},on:{"data-saved":function($event){_vm.fetchCampaigns();
        _vm.showDialog = false;},"cancel":function($event){_vm.showDialog = false}}}):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v("   ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticStyle:{"float":"right"},on:{"click":function($event){_vm.campaign = null;
          _vm.showDialog = true;}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-plus")]),_vm._v(" Create ")],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.campaignPage.docs,"options":_vm.options,"server-items-length":_vm.campaignPage.total,"no-data-text":"No campaign to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.type)+" ")]}},{key:"item.title",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.startedOn",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startedOn))+" ")]}},{key:"item.expiresOn",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.expiresOn))+" ")]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.editCampaign(item)}}},[_vm._v(" Edit ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }